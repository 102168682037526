<template>
  <v-row>
    <v-col cols="12" class="pa-0 pb-5">
      <v-skeleton-loader class="rounded-md" v-if="$store.state.accounts.retrieveLoader" transition="fade-transition" type="article"/>
      <v-row no-gutters v-else>
        <v-col cols="6" class="py-0 px-2">
          <v-card flat>
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-bold">
                <v-row no-gutters align="center" justify="space-between">
                  <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon size="16" left color="grey-700">mdi-domain</v-icon>Información de la organización</span>
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="px-5 py-4">
              <v-list class="v-list-form-basic transparent pa-0">
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-domain</v-icon>
                    Nombre
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <span class="d-block d-inline-block text-truncate" style="max-width: 99%;" v-if="editable.name">{{ editable.name }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-earth</v-icon>
                    País
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <span class="d-block" v-if="editable.country">{{ countriesList.find(({id}) => editable.country == id)?.name }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-map-marker</v-icon>
                    {{$t('generals.Comuna')}}
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <span class="d-block" v-if="editable.state">{{ editable.state }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-map-marker</v-icon>
                    Dirección
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <span class="d-block d-inline-block text-truncate" style="max-width: 99%;" v-if="editable.address">{{ editable.address }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" class="py-0 px-2">
          <v-card flat>
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-bold">
                <v-row no-gutters align="center" justify="space-between">
                  <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon size="16" left color="grey-700">mdi-bank</v-icon>Información de contacto</span>
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="px-5 py-4">
              <v-list class="v-list-form-basic transparent pa-0">
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-email</v-icon>
                    Email
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <span class="d-block" v-if="editable.email">{{ editable.email }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-phone</v-icon>
                    Teléfono
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <span class="d-block" v-if="editable.phone">{{ editable.phone }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-web</v-icon>
                    Sitio web
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <span class="d-block" v-if="editable.website">{{ editable.website }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-email</v-icon>
                    Correo de emisión
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <v-tooltip color="grey-background" right transition="scale-transition">
                      <template v-slot:activator="{on}">
                        <v-btn class="font-weight-regular" @click="toClipboard('dte@axteroid.com')" text color="grey-700" v-on="on" :ripple="false">dte@axteroid.com</v-btn>
                      </template>
                      <span class="d-block px-3 py-2">Copiar</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" class="px-2 mt-5" v-if="$store.state.auth.account.country === 'CL'">
          <v-card flat>
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-bold">
                <v-row no-gutters align="center" justify="space-between">
                  <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon size="16" left color="grey-700">mdi-bank</v-icon>Información fiscal</span>
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="px-5 py-4">
              <v-list class="v-list-form-basic transparent pa-0">
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-pound-box-outline</v-icon>
                    {{$t('generals.Número fiscal')}}
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <span class="d-block" v-if="editable.tax_id">{{ editable.tax_id }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-bank</v-icon>
                    <span class="d-inline-block text-truncate" style="max-width: 99%;">Descripción de la actividad</span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <span class="d-block body-1 grey-700--text d-inline-block text-truncate" style="max-width: 99%;" v-if="irsConnection?.activity_description">{{ irsConnection.activity_description }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-pound-box-outline</v-icon>
                    <span class="d-inline-block text-truncate" style="max-width: 99%;">Código de actividad SII</span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                     <span class="d-block body-1 grey-700--text" v-if="irsConnection?.activity_code">{{ irsConnection.activity_code }}</span>
                  <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-pound-box-outline</v-icon>
                    Fecha de resolución
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <span class="d-block body-1 grey-700--text" v-if="irsConnection?.sii_resolution_date">{{ irsConnection.sii_resolution_date | dateTimeSecond }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-title class="font-weight-semibold d-flex align-center mr-5">
                    <v-icon color="grey-700" size="14" left>mdi-pound-box-outline</v-icon>
                    Número resolución
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-1 grey-500--text">
                    <span class="d-block body-1 grey-700--text" v-if="irsConnection?.sii_resolution_number">{{ irsConnection.sii_resolution_number }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, minLength, maxLength, email, url } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import cloneDeep from 'lodash/cloneDeep'
import activitiesList from '@/collections/activitiesSIIList'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {
    PermissionDenied
  },
  data: () => ({
    activitiesList,
    mainLoader: false,
    editable: {},
    backupEditable: {},
    irsConnection: {
      // test_mode: false,
      activity_code: null,
      activity_description: '',
      sii_resolution_date: '',
      sii_resolution_number: null
      // document_assignable_copy: false
    },
    backupIrsConnection: {},
    datePickerMenu: false
  }),
  computed: mapState({
    statesList: state => state.base.statesList,
    countriesList: state => state.base.countriesList
    // accountsDetail: state => state.accounts.accountsDetail,
  }),
  created () {
    if (this.$helpers.hasPermission(['view_account'])) {
        this.$store.dispatch('irs/LIST', {
        resource: 'login/realms/axt/custom-resources/organizations',
        query: { acc_id: this.$store.state.auth.account.acc_id}
      })
      .then((response) => {
        this.irsConnection = {
          activity_code: response.data?.activity_code ?? null,
          activity_description: response.data?.activity_description ?? '',
          sii_resolution_date: response.data?.sii_resolution_date ?? '',
          sii_resolution_number: response.data?.sii_resolution_number ?? null
        }
      })
      // this.$store.dispatch('accounts/LIST', {
      //   resource: 'login/realms/axt/custom-resources/workspaces',
      //   query: { acc_id: this.$store.state.auth.account.acc_id }
      // })
      // .then(() => {
          this.editable = this.$store.state.auth.account
          this.backupEditable = this.$store.state.auth.account
        // })
    }
  },
  methods: {
    patchAccount () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }
      const payload = {
        name: this.editable.name,
        state: this.editable.state.id || this.editable.state,
        address: this.editable.address,
        email: this.editable.email,
        phone: this.editable.phone,
        website: this.editable.website
      }
      this.$store.dispatch('accounts/UPDATE', {
        resource: 'login/realms/axt/custom-resources/workspaces',
        payload: payload,
        id: this.editable.id
      })
      .then(() => {
        this.$dialog.message.info('La información de la organización se ha actualizado')
      })
      .catch((error) => {
        this.$dialog.message.warning(this.parseErrors(error.response.data))
      })
    },
    resetForm () {
      this.editable = cloneDeep(this.backupEditable)
    },
    resetFormIrsConnection () {
      this.irsConnection = {...this.backupIrsConnection}
    },
    createIrsConnection () {
      this.$v.irsConnection.$touch()

      if (this.$v.irsConnection.$invalid) {
        return false
      }

      const payload = {
        api_key: 'api_key',
        irs: 'sii',
        extra_data: this.irsConnection
      }

      this.$store.dispatch('irs/CREATE', {
        resource: 'irs/connections',
        payload: payload
      })
      .then(() => {
        this.$dialog.message.info('La información fiscal de la organización se ha actualizado')
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
    },
    updateIrsConnection () {
      this.$v.irsConnection.$touch()

      if (this.$v.irsConnection.$invalid) {
        return false
      }

      const payload = {
        api_key: 'api_key',
        irs: 'sii',
        extra_data: this.irsConnection
      }

      this.$store.dispatch('irs/UPDATE', {
        resource: 'irs/connections',
        id: this.instance.id,
        payload: payload
      })
      .then(() => {
        this.$dialog.message.info('La información fiscal de la organización se ha actualizado')
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
    }
  },
  validations: {
    editable: {
      name: {
        required,
        maxLength: maxLength(64)
      },
      state: {
        required
      },
      address: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(128)
      },
      email: {
        required,
        email,
        maxLength: maxLength(254)
      },
      phone: {
        maxLength: maxLength(16)
      },
      website: {
        url
      }
    },
    irsConnection: {
      activity_code: {
        required
      },
      activity_description: {
        required,
        maxLength: maxLength(56)
      }
    }
  }
}
</script>